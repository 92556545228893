const mode = "LIVE"; // DEV, LIVE

const API = {
    BASE_URL: "",
    INVESTORS: "",
    MEETING_FORM: "",
    CONTACTUS_FORM: "",
}

if (mode === "DEV") {
    API["BASE_URL"] = "https://devarus.aftergen.in/dev/API/release_v45/";
    API["MEETING_FORM"] = "meeting.php";
    API['INVESTORS'] = "investor.php";
    API['CONTACTUS_FORM'] = "https://backend.hifrds.com/pepul/addContactUs.php";
}

if (mode === "LIVE"){
    API["BASE_URL"] = "https://backend.hifrds.com/pepul/";
    API["MEETING_FORM"] = "addMeeting.php";
    API['INVESTORS'] = "addInvestor.php";
    API['CONTACTUS_FORM'] = "https://backend.hifrds.com/pepul/addContactUs.php";
}

export default API