import React from 'react'
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import MeetingForm from './pages/meeting-form';
import InvestorFrom from './pages/investors-form';
import ContactusForm from './pages/contactus-form';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path='/meetingrequest' element={<MeetingForm/>}/>
        <Route path='/investors' element={<InvestorFrom/>}/>
        <Route path='/contactus' element={<ContactusForm/>}/>
      </Routes>
    </Router>
  )
}

