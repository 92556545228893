import React from 'react'
// import { RxCross1 } from "react-icons/rx";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { FaArrowRight, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import { RiCornerDownLeftFill } from "react-icons/ri";
import { IoMdCheckmark } from "react-icons/io";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import axios from 'axios';
import API from '../../utils/API';
import { FaThumbsUp } from "react-icons/fa";



export default function ContactusForm() {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [linkedin, setLinkedin] = React.useState('');
    const [mobileNumber, setMobileNumber] = React.useState('');
    const [reason, setReason] = React.useState('');
    const [risk, setRisk] = React.useState('');
    const [investment, setInvestment] = React.useState('');
    const [anything, setAnything] = React.useState('');
    const [selectedCountry, setSelectedCountry] = React.useState('us');

    const [nameError, setNameError] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [linkedinError, setLinkedinError] = React.useState('');
    const [mobileNumberError, setMobileNumberError] = React.useState('');
    const [reasonError, setReasonError] = React.useState('');
    const [investmentError, setInvestmentError] = React.useState('');
    const [anythingError, setAnythingError] = React.useState('');

    const [isName, setIsName] = React.useState(false);
    const [isEmail, setIsEmail] = React.useState(false);
    const [isLinkedin, setIsLinkedin] = React.useState(false);
    const [isMobileNumber, setIsMobileNumber] = React.useState(false);
    const [isReason, setIsReason] = React.useState(false);
    const [isRisk, setIsRisk] = React.useState(false);
    const [isInvestment, setIsInvestment] = React.useState('');
    const [isAnything, setIsAnything] = React.useState(false);
    const [isResponse, setIsResponse] = React.useState(false);

    const [otherReason, setOtherReason] = React.useState({ value: '', reason: '' });
    const invalidCharsRegex = /[<>'/\\;’”:{}[\]|+=\-_()*&^%$#!~`]/;

    const handleNameSubmit = () => {
        if (!name.trim()) {
            setNameError('Please fill in this')
        }
        else if (invalidCharsRegex.test(name)) {
            setNameError("invalid special characters")
        }
        else {
            setIsName(true);
        }
    }

    const handleEmailSubmit = () => {
        const gmailRegex = /^[^\s@]+@[^\s@]+\.(?:com)$/i;
        if (email === "") {
            setEmailError('Please fill in this');
        }
        else if (invalidCharsRegex.test(email)) {
            setEmailError("invalid special characters")
        }
        else if (!gmailRegex.test(email)) {
            setEmailError("Hmm... that email doesn't look right");
        } else {
            setIsEmail(true);
        }
    }

    const handleMobileNumberSubmit = () => {
        if (mobileNumber === "") {
            setMobileNumber('Please fill in this');
        }
        else if (mobileNumber.length < 10) {
            setMobileNumberError("Hmm... that mobile number doesn't look right");
        } else {
            setIsMobileNumber(true);
        }
    }

    const handleAnythingSubmit = () => {
        if (!anything.trim()) {
            setAnythingError('Please fill in this')
        }
        else if (invalidCharsRegex.test(name)) {
            setAnythingError("invalid special characters")
        }
    }


    const handleUpArrow = () => {
        if (isAnything) {
            setIsAnything(false)
        }
        else if (isMobileNumber) {
            setIsMobileNumber(false)
        }
        else if (isEmail) {
            setIsEmail(false)
        }
        else if (isName) {
            setIsName(false)
        }
    }


    const handleDownArrow = () => {
        if (isName && isEmail && isMobileNumber && !isAnything) {
            if (!anything.trim()) {
                setAnythingError(true)
            } else {
                setIsAnything(true);
            }
        }
        else if (isName && isEmail && !isMobileNumber) {
            if (mobileNumber === "") {
                setMobileNumber('Please fill in this');
            }
            else if (mobileNumber.length < 10) {
                setMobileNumberError("Hmm... that mobile number doesn't look right");
            } else {
                setIsMobileNumber(true);
            }
        }
        else if (isName && !isEmail) {
            const gmailRegex = /^[^\s@]+@[^\s@]+\.(?:com)$/i;
            if (email === "") {
                setEmailError('Please fill in this');
            }
            else if (invalidCharsRegex.test(email)) {
                setEmailError("invalid special characters")
            }
            else if (!gmailRegex.test(email)) {
                setEmailError("Hmm... that email doesn't look right");
            } else {
                setIsEmail(true);
            }
        }
        else if (!isName) {
            if (!name.trim()) {
                setNameError('Please fill in this')
            }
            else if (invalidCharsRegex.test(name)) {
                setNameError("invalid special characters")
            }
            else {
                setIsName(true);
            }
        }
    }


    const handleSubmit = async (e) => {
        try {
            if (!anything.trim()) {
                setAnythingError('Please fill in this')
            }
            else if (invalidCharsRegex.test(name)) {
                setAnythingError("invalid special characters")
            } else {
                const response = await axios.post(`${API.CONTACTUS_FORM}`, { name: name, email: email, countryCode: selectedCountry, mobileNumber: mobileNumber, message: anything });
                console.log(response.data)
                if (response.data.statusCode === 200) {
                    setIsResponse(true);
                    setName('');
                    setEmail('');
                    setSelectedCountry('');
                    setMobileNumber('');
                    setAnything('');
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div className='relative h-screen bg-[#000]'>
            {isResponse && (
                <>
                    <div className='fixed w-full h-screen overflow-hidden bg-[#66666699] z-[1] inset-0'></div>
                    <div className='absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] bg-[#000] w-full max-w-[700px] h-full max-h-[600px] rounded-[30px] z-[2] flex flex-col gap-[10px] justify-center items-center'>
                        <h1 className='text-[#FFF] text-[30px] font-bold'>Your Form Has</h1>
                        <h1 className='text-[#FFF] text-[30px] font-bold'>Been <span className='text-[#0142ac]'>Successfully</span></h1>
                        <h1 className='text-[#FFF] text-[30px] font-bold'>Submitted</h1>
                        <FaThumbsUp className='text-[#FFF] text-[30px] font-bold' />
                        <h1 className='text-[#FFF] text-[30px] font-semibold text-center'>Our Team Will Respond And Do The Needful</h1>
                        <button className='bg-[#0142ac] p-3 mt-[70px] rounded-md text-[#FFF] text-[30px] font-semibold' onClick={() => window.location.href = 'https://www.pepul.com/vision'}>Click Here</button>
                    </div>
                </>
            )}
            {/* <RxCross1 className="text-[#FFF] absolute top-3 right-3 w-6 h-6" /> */}
            <div className='p-6 relative h-screen w-full'>
                <div class="w-full bg-[#0142ac4d] rounded-full h-[4px] mt-5 xl:mt-4">
                    <div class={`bg-[#0142ac] h-full rounded-full transition-all duration-200 ${anything ? "w-full" : mobileNumber.replace(`+${selectedCountry}`, '') ? "w-[75%]" : email ? "w-[50%]" : name ? "w-[25%]" : "w-0"}`}></div>
                </div>
                <div className='flex flex-col justify-start items-center h-full overflow-y-hidden' >
                    {/* ----------- Name -----------  */}
                    <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                        ${isName ? "opacity-0 -translate-y-full" : ""}`
                    }>
                        <div className='relative'>
                            <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                <h1 className='text-[#e6e6e6] flex shrink-0 text-[14px] sm:text-[18px]'>1</h1>
                                <FaArrowRight className='text-[#e6e6e6] flex shrink-0 ' />
                            </div>
                            <div className='text-[#e0d9d9]'>
                                <h1 className='text-xl sm:text-2xl font-light'>Hi! What is Your Name ?*</h1>
                            </div>
                            <div className='mt-[20px] sm:mt-[32px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px]'>
                                <input type='text' value={name} placeholder='Type your answer here...' className='max-w-full w-full bg-transparent border-none pb-2 placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#e6e6e6] placeholder:text-[#4f4946] focus:outline-none focus:shadow-focus shadow-focus cursor-pointer' onChange={(e) => { setName(e.target.value); setNameError('') }} />
                            </div>
                            {nameError ?
                                (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                            <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                            <h1 className='text-[#af0404] text-sm'>{nameError}</h1>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleNameSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                    </div>
                                )}
                        </div>
                    </div>
                    {/* ----------- Email -----------  */}
                    <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0
                        ${isEmail ? "opacity-0 -translate-y-[200%]" :
                            isName ? "opacity-1 -translate-y-full" : ""}`}
                    >
                        <div className='relative'>
                            <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                <h1 className='text-[#e6e6e6] flex shrink-0 text-[14px] sm:text-[18px]'>2</h1>
                                <FaArrowRight className='text-[#e6e6e6] flex shrink-0 ' />
                            </div>
                            <div className='text-[#e0d9d9]'>
                                <h1 className='text-xl sm:text-2xl font-light'>What’s your email address ? *</h1>
                            </div>
                            <div className='mt-[20px] sm:mt-[32px] w-full max-w-[280px] sm:max-w-[530px] lg:max-w-[720px]'>
                                <input type='email' value={email} placeholder='name@example.com' className='max-w-full w-full bg-transparent border-none pb-2 placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#e6e6e6] placeholder:text-[#4f4946] focus:outline-none focus:shadow-focus shadow-focus cursor-pointer' onChange={(e) => { setEmail(e.target.value); setEmailError('') }} />
                            </div>
                            {emailError ?
                                (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                            <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                            <h1 className='text-[#af0404] text-sm'>{emailError}</h1>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleEmailSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                        {/* <h1 className='text-[#e0d9d9] text-xs flex items-center gap-1'>press <strong>Enter</strong> <RiCornerDownLeftFill className='w-3 h-3' /> </h1> */}
                                    </div>
                                )}
                        </div>
                    </div>
                    {/* ----------- Mobile Number -----------  */}
                    <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                        ${isMobileNumber ? "opacity-1 -translate-y-[300%]" :
                            isEmail ? "opacity-1 -translate-y-[200%]" :
                                isName ? "opacity-1 -translate-y-full" : ""}`
                    }>
                        <div className='relative'>
                            <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                <h1 className='text-[#e6e6e6] flex shrink-0 text-[14px] sm:text-[18px]'>3</h1>
                                <FaArrowRight className='text-[#e6e6e6] flex shrink-0' />
                            </div>
                            <div className='text-[#e0d9d9]'>
                                <h1 className='text-xl sm:text-2xl font-light'>Contact Number*</h1>
                            </div>
                            <PhoneInput country={"us"} value={mobileNumber} onChange={(phone, country) => { setMobileNumber(phone); setSelectedCountry(country?.country?.dialCode); setMobileNumberError(''); }} className="max-h-[40px] sm:max-h-[60px] h-full mt-[20px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px] focus:outline-none cursor-pointer" />
                            {mobileNumberError ?
                                (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                            <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                            <h1 className='text-[#af0404] text-sm'>{mobileNumberError}</h1>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleMobileNumberSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                    </div>
                                )}
                        </div>
                    </div>
                    {/* ----------- Anything -----------  */}
                    <div className={`flex flex-col justify-center w-10/12 sm:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 lg:p-6 xl:p-0
                        ${isInvestment ? "opacity-1 -translate-y-[400%]" :
                            isMobileNumber ? "opacity-1 -translate-y-[300%]" :
                                isEmail ? "opacity-1 -translate-y-[200%]" :
                                    isName ? "opacity-1 -translate-y-full" : ""}`}
                    >
                        <div className='relative'>
                            <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                <h1 className='text-[#e6e6e6] flex shrink-0 text-[14px] sm:text-[18px]'>4</h1>
                                <FaArrowRight className='text-[#e6e6e6] flex shrink-0 ' />
                            </div>
                            <div className='text-[#e0d9d9]'>
                                <h1 className='text-xl sm:text-2xl font-light'>Please Enter Your Message</h1>
                            </div>
                            <div className='mt-[20px] sm:mt-[32px] w-full max-w-[310px] sm:max-w-[550px] lg:max-w-[720px]'>
                                <input type='text' value={anything} placeholder='Type your answer here...' className='max-w-full w-full bg-transparent border-none pb-2 placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#e6e6e6] placeholder:text-[#4f4946] focus:outline-none focus:shadow-focus shadow-focus cursor-pointer' onChange={(e) => { setAnything(e.target.value); setAnythingError('') }} />
                            </div>
                            {anythingError ?
                                (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                            <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                            <h1 className='text-[#af0404] text-sm'>{anythingError}</h1>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <button type='button' className='min-h-10 py-1.5 px-3.5 w-full md:w-auto mx-auto xl:mx-0 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center justify-center text-md sm:text-xl gap-1 font-semibold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleSubmit}><h1>Submit</h1></button>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div className='flex justify-end absolute bottom-8 right-8'>
                    <button className={`bg-[#0142ac] transition-colors duration-200 w-9 h-8 border-r-[1px] border-[#ffffff99] rounded-s-[4px] flex justify-center items-center ${isName ? "hover:bg-[#0142ac99] text-[#fff]" : "text-[#ffffff99]"}`} disabled={!isName} onClick={handleUpArrow}><FaChevronUp className='w-4 h-4' /></button>
                    <button className={`bg-[#0142ac] transition-colors duration-200 w-9 h-8 rounded-e-[4px] flex justify-center items-center ${isMobileNumber ? "text-[#ffffff99]" : "text-[#ffffff] hover:bg-[#0142ac99]"}`} onClick={handleDownArrow} disabled={isMobileNumber}><FaChevronDown className='w-4 h-4' /></button>
                </div>
            </div>
        </div >
    )
}
