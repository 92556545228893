import React from 'react'
import { RxCross1 } from "react-icons/rx";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { FaArrowRight, FaChevronDown, FaChevronUp, FaThumbsUp } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";
import Logo from "../../images/pepul_logo.svg";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import axios from 'axios';
import API from '../../utils/API';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs'; 


export default function MeetingForm() {
    const [name, setName] = React.useState('');
    const [mobileNumber, setMobileNumber] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [socialMedia, setSocialMedia] = React.useState('');
    const [appointmentType, setAppointmentType] = React.useState('');
    const [agenda, setAgenda] = React.useState('');
    const [duration, setDuration] = React.useState('');
    const [eventName, setEventName] = React.useState('');
    const [institutionName, setInstitutionName] = React.useState('');
    const [date, setDate] = React.useState(dayjs());
    const [agenda1, setAgenda1] = React.useState('');
    const [audienceType, setAudienceType] = React.useState('');
    const [audienceNumber, setAudienceNumber] = React.useState('');
    const [guest, setGuest] = React.useState('');
    const [linkedin, setLinkedin] = React.useState('');
    const [paid, setPaid] = React.useState('');
    const [mode, setMode] = React.useState('');
    const [location, setLocation] = React.useState('');
    const [selectedCountry, setSelectedCountry] = React.useState('us'); 


    const [nameError, setNameError] = React.useState('');
    const [mobileNumberError, setMobileNumberError] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [socialMediaError, setSocialMediaError] = React.useState('');
    const [appointmentTypeError, setAppointmentTypeError] = React.useState('');
    const [agendaError, setAgendaError] = React.useState('');
    const [durationError, setDurationError] = React.useState('');
    const [eventNameError, setEventNameError] = React.useState('');
    const [institutionNameError, setInstitutionNameError] = React.useState('');
    const [dateError, setDateError] = React.useState(false);
    const [agenda1Error, setAgenda1Error] = React.useState('');
    const [audienceTypeError, setAudienceTypeError] = React.useState('');
    const [audienceNumberError, setAudienceNumberError] = React.useState('');
    const [guestError, setGuestError] = React.useState('');
    const [linkedinError, setLinkedinError] = React.useState('');
    const [paidError, setPaidError] = React.useState('');
    const [modeError, setModeError] = React.useState('');
    const [locationError, setLocationError] = React.useState('');



    const [isName, setIsName] = React.useState(false);
    const [isMobileNumber, setIsMobileNumber] = React.useState(false);
    const [isEmail, setIsEmail] = React.useState(false);
    const [isSocialMedia, setIsSocialMedia] = React.useState(false);
    const [isAppointmentType, setIsAppointmentType] = React.useState(false);
    const [isAgenda, setIsAgenda] = React.useState(false);
    const [isDuration, setIsDuration] = React.useState(false);
    const [isEventName, setIsEventName] = React.useState(false);
    const [isInstitutionName, setIsInstitutionName] = React.useState(false);
    const [isDate, setIsDate] = React.useState(false);
    const [isAgenda1, setIsAgenda1] = React.useState(false);
    const [isAudienceType, setIsAudienceType] = React.useState(false);
    const [isAudienceNumber, setIsAudienceNumber] = React.useState(false);
    const [isGuest, setIsGuest] = React.useState(false);
    const [isLinkedin, setIsLinkedin] = React.useState(false);
    const [isPaid, setIsPaid] = React.useState(false);
    const [isMode, setIsMode] = React.useState(false);
    const [isLocation, setIsLocation] = React.useState(false);
    const [isResponse, setIsResponse] = React.useState(false);


    const invalidCharsRegex = /[<>'/\\;’”:{}[\]|+=\-_()*&^%$#!~`]/;

    const handleNameSubmit = () => {
        if (!name.trim()) {
            setNameError('Please fill in this')
        }
        else if (invalidCharsRegex.test(name)) {
            setNameError("invalid special characters")
        }
        else {
            setIsName(true);
        }
    }

    const handleMobileNumberSubmit = () => {
        console.log(mobileNumber);
        if (mobileNumber === "") {
            setMobileNumber('Please fill in this');
        }
        else if (mobileNumber.length < 10) {
            setMobileNumberError("Hmm... that mobile number doesn't look right");
        } else {
            setIsMobileNumber(true);
        }
    }

    const handleEmailSubmit = () => {
        const gmailRegex = /^[^\s@]+@[^\s@]+\.(?:com)$/i;
        if (email === "") {
            setEmailError('Please fill in this');
        }
        else if (invalidCharsRegex.test(email)) {
            setEmailError("invalid special characters")
        }
        else if (!gmailRegex.test(email)) {
            setEmailError("Hmm... that email doesn't look right");
        } else {
            setIsEmail(true);
        }
    }

    const handleSocialMediaSubmit = () => {
        const socialMediaRegex = {
            linkedin: /www\.linkedin\.com\/in\/[a-zA-Z0-9-]+\/?/,
            facebook: /www\.facebook\.com\/[a-zA-Z0-9-]+\/?/,
            twitter: /www\.twitter\.com\/[a-zA-Z0-9_]+\/?/,
            instagram: /www\.instagram\.com\/[a-zA-Z0-9_]+\/?/,
            pepul: /www\.pepul\.com\/[a-zA-Z0-9_]+\/?/
        };
        if (socialMedia === "") {
            setSocialMediaError('Please fill in this');
        }
        else {
            let isValidSocialMedia = false;
            let errorMessage = "";
            for (const platform in socialMediaRegex) {
                if (socialMediaRegex.hasOwnProperty(platform)) {
                    if (socialMediaRegex[platform].test(socialMedia)) {
                        isValidSocialMedia = true;
                        break;
                    } else {
                        errorMessage = "Hmm… that web address doesn’t look right. Check for any typos or errors.";
                    }
                }
            }
            if (isValidSocialMedia) {
                setIsSocialMedia(true);
            } else {
                setSocialMediaError(errorMessage);
            }
        }
    };

    const handleAppointmentType = (value) => {
        setAppointmentType((prevValue) => (prevValue === value ? '' : value));
    };

    const handleAppointmentTypeSubmit = () => {
        if (appointmentType === "") {
            setAppointmentTypeError('Oops! Please make a selection')
        }
        else {
            setAppointmentTypeError('');
            setIsAppointmentType(true)
        }
    }


    const handleAgendaSubmit = () => {
        if (!agenda.trim()) {
            setAgendaError('Please fill in this')
        }
        else if (invalidCharsRegex.test(agenda)) {
            setAgendaError("invalid special characters")
        }
        else {
            setIsAgenda(true);
        }
    }

    const handleDuration = (value) => {
        setDuration((prevValue) => (prevValue === value ? '' : value));
    };

    const handleEventNameSubmit = () => {
        if (!eventName.trim()) {
            setEventNameError('Please fill in this')
        }
        else if (invalidCharsRegex.test(eventName)) {
            setEventNameError("invalid special characters")
        }
        else {
            setIsEventName(true);
        }
    }

    const handleInstitutionNameSubmit = () => {
        if (!institutionName.trim()) {
            setInstitutionNameError('Please fill in this')
        }
        else if (invalidCharsRegex.test(institutionName)) {
            setInstitutionNameError("invalid special characters")
        }
        else {
            setIsInstitutionName(true);
        }
    }

    const handleDateChange = (date) => {
        setDate(date?.$d);
        setDateError(false)
    };

    const handleDateSubmit = () => {
        if (date === "" || date === undefined) {
            setDateError(true)
        }
        else {
            setIsDate(true);
        }
    }

    const handleAgenda1Submit = () => {
        if (!agenda1.trim()) {
            setAgenda1Error('Please fill in this')
        }
        else if (invalidCharsRegex.test(agenda1)) {
            setAgenda1Error("invalid special characters")
        }
        else {
            setIsAgenda1(true);
        }
    }

    const handleAudienceTypeSubmit = () => {
        if (!audienceType.trim()) {
            setAudienceTypeError('Please fill in this')
        }
        else if (invalidCharsRegex.test(audienceType)) {
            setAudienceTypeError("invalid special characters")
        }
        else {
            setIsAudienceType(true);
        }
    }


    const handleAudienceNumberSubmit = () => {
        if (!audienceNumber.trim()) {
            setAudienceNumberError('please fill in this')
        }
        else if (invalidCharsRegex.test(audienceNumber)) {
            setAudienceNumberError("invalid special characters")
        }
        else {
            setIsAudienceNumber(true);
        }
    }

    const handleGuest = (value) => {
        setGuest((prevValue) => (prevValue === value ? '' : value));
    };

    const handleGuestSubmit = () => {
        if (guest === "") {
            setGuestError('Oops! Please make a selection')
        }
        else if (invalidCharsRegex.test(guest)) {
            setGuestError("invalid special characters")
        }
        else {
            setGuestError('');
            setIsGuest(true)
        }
    }

    const handleLinkedinSubmit = () => {
        const linkedinRegex = /www\.linkedin\.com\/in\/[a-zA-Z0-9-]+\/?/;
        if (linkedin === "") {
            setLinkedinError('Please fill this in');
        }
        else if (!linkedinRegex.test(linkedin)) {
            setLinkedinError("Hmm… that web address doesn’t look right. Check for any typos or errors.");
        } else {
            setIsLinkedin(true);
        }
    }

    const handlePaid = (value) => {
        setPaid((prevValue) => (prevValue === value ? '' : value));
    };

    const handlePaidSubmit = () => {
        if (paid === "") {
            setPaidError('Oops! Please make a selection')
        }
        else if (invalidCharsRegex.test(paid)) {
            setPaidError("invalid special characters")
        }
        else {
            setPaidError('');
            setIsPaid(true)
        }
    }

    const handleMode = (value) => {
        setMode((prevValue) => (prevValue === value ? '' : value));
    };

    const handleModeSubmit = () => {
        if (mode === "") {
            setModeError('Oops! Please make a selection')
        }
        else if (invalidCharsRegex.test(mode)) {
            setModeError("invalid special characters")
        }
        else {
            setModeError('');
            setIsMode(true)
        }
    }

    const handleLocationSubmit = () => {
        if (!location.trim()) {
            setLocationError("please fill in this")
        }
        else if (invalidCharsRegex.test(location)) {
            setLocationError("invalid special characters")
        }
        else {
            setIsLocation(true);
        }
    }


    const handleUpArrow = () => {
        if (isLocation) {
            setIsLocation(false)
        }
        else if (isMode) {
            setIsMode(false)
        }
        else if (isPaid) {
            setIsPaid(false)
        }
        else if (isLinkedin) {
            setIsLinkedin(false)
        }
        else if (isGuest) {
            setIsGuest(false)
        }
        else if (isAudienceNumber) {
            setIsAudienceNumber(false)
        }
        else if (isAudienceType) {
            setIsAudienceType(false)
        }
        else if (isAgenda1) {
            setIsAgenda1(false)
        }
        else if (isDate) {
            setIsDate(false)
        }
        else if (isInstitutionName) {
            setIsInstitutionName(false)
        }
        else if (isEventName) {
            setIsEventName(false)
        }
        else if (isDuration) {
            setIsDuration(false)
        }
        else if (isAgenda) {
            setIsAgenda(false)
        }
        else if (isAppointmentType) {
            setIsAppointmentType(false)
        }
        else if (isSocialMedia) {
            setIsSocialMedia(false)
        }
        else if (isEmail) {
            setIsEmail(false)
        }
        else if (isMobileNumber) {
            setIsMobileNumber(false)
        }
        else if (isName) {
            setIsName(false)
        }
    }


    const handleDownArrow = () => {
        if (mode === "Offline" && isMode && !isLocation) {
            if (!location.trim()) {
                setLocationError("please fill in this")
            }
            else if (invalidCharsRegex.test(location)) {
                setLocationError("invalid special characters")
            }
            else {
                setIsLocation(true);
            }
        }
        else if (isPaid && !isMode) {
            if (mode === "") {
                setModeError('Oops! Please make a selection')
            }
            else if (invalidCharsRegex.test(mode)) {
                setModeError("invalid special characters")
            }
            else {
                setModeError('');
                setIsMode(true)
            }
        }
        else if ((guest === "Yes" && isGuest && isLinkedin && !isPaid) || (guest === "No" && isGuest && !isPaid)) {
            if (paid === "") {
                setPaidError('Oops! Please make a selection')
            }
            else if (invalidCharsRegex.test(paid)) {
                setPaidError("invalid special characters")
            }
            else {
                setPaidError('');
                setIsPaid(true)
            }
        }
        else if (isGuest && !isLinkedin) {
            const linkedinRegex = /www\.linkedin\.com\/in\/[a-zA-Z0-9-]+\/?/;
            if (linkedin === "") {
                setLinkedinError('Please fill in this');
            }
            else if (!linkedinRegex.test(linkedin)) {
                setLinkedinError("Hmm… that web address doesn’t look right. Check for any typos or errors.");
            } else {
                setIsLinkedin(true);
            }
        }
        else if (isAudienceNumber && !isGuest) {
            if (guest === "") {
                setGuestError('Oops! Please make a selection')
            }
            else if (invalidCharsRegex.test(guest)) {
                setGuestError("invalid special characters")
            }
            else {
                setGuestError('');
                setIsGuest(true)
            }
        }
        else if (isAudienceType && !isAudienceNumber) {
            if (!audienceNumber.trim()) {
                setAudienceNumberError('Please fill in this')
            }
            else if (invalidCharsRegex.test(audienceNumber)) {
                setAudienceNumberError("invalid special characters")
            }
            else {
                setIsAudienceNumber(true);
            }
        }
        else if (isAgenda1 && !isAudienceType) {
            if (!audienceType.trim()) {
                setAudienceTypeError('please fill in this')
            }
            else if (invalidCharsRegex.test(audienceType)) {
                setAudienceTypeError("invalid special characters")
            }
            else {
                setIsAudienceType(true);
            }
        }
        else if (isDate && !isAgenda1) {
            if (!agenda1.trim()) {
                setAgenda1Error('please fill in this')
            }
            else if (invalidCharsRegex.test(agenda1)) {
                setAgendaError("invalid special characters")
            }
            else {
                setIsAgenda1(true);
            }
        }
        else if (isInstitutionName && !isDate) {
            if (date === "" || date === undefined) {
                setDateError(true)
            } else {
                setIsDate(true);
            }
        }
        else if (isEventName && !isInstitutionName) {
            if (!institutionName.trim()) {
                setInstitutionNameError('please fill in this')
            }
            else if (invalidCharsRegex.test(institutionName)) {
                setInstitutionNameError("invalid special characters")
            }
            else {
                setIsInstitutionName(true);
            }
        }
        else if ((appointmentType === "Invite as Chief Guest" || appointmentType === "Invite as Panel Judge" || appointmentType === "Invite as speaker") && isAppointmentType && !isAgenda && !isEventName) {
            if (!eventName.trim()) {
                setEventNameError('please fill in this')
            }
            else if (invalidCharsRegex.test(eventName)) {
                setEventNameError("invalid special characters")
            }
            else {
                setIsEventName(true);
            }
        }
        else if ((appointmentType === "1:1 Business Proposal" || appointmentType === "1:1 Casual Meet" || appointmentType === "Other") && isAppointmentType && !isAgenda) {
            if (!agenda.trim()) {
                setAgendaError('please fill in this')
            }
            else if (invalidCharsRegex.test(agenda)) {
                setAgendaError("invalid special characters")
            }
            else {
                setIsAgenda(true);
            }
        }
        else if (isSocialMedia && !isAppointmentType) {
            if (appointmentType === "") {
                setAppointmentTypeError('Oops! Please make a selection')
            }
            else {
                setAppointmentTypeError('');
                setIsAppointmentType(true)
            }
        }
        else if (isEmail && !isSocialMedia) {
            const socialMediaRegex = {
                linkedin: /www\.linkedin\.com\/in\/[a-zA-Z0-9-]+\/?/,
                facebook: /www\.facebook\.com\/[a-zA-Z0-9-]+\/?/,
                twitter: /www\.twitter\.com\/[a-zA-Z0-9_]+\/?/,
                instagram: /www\.instagram\.com\/[a-zA-Z0-9_]+\/?/,
                pepul: /www\.pepul\.com\/[a-zA-Z0-9_]+\/?/
            };
            if (socialMedia === "") {
                setSocialMediaError('Please fill this in');
            }
            else {
                let isValidSocialMedia = false;
                let errorMessage = "";
                for (const platform in socialMediaRegex) {
                    if (socialMediaRegex.hasOwnProperty(platform)) {
                        if (socialMediaRegex[platform].test(socialMedia)) {
                            isValidSocialMedia = true;
                            break;
                        } else {
                            errorMessage = "Hmm… that web address doesn’t look right. Check for any typos or errors.";
                        }
                    }
                }
                if (isValidSocialMedia) {
                    setIsSocialMedia(true);
                } else {
                    setSocialMediaError(errorMessage);
                }
            }
        }
        else if (isName && isMobileNumber && !isEmail) {
            const gmailRegex = /^[^\s@]+@[^\s@]+\.(?:com)$/i;
            if (email === "") {
                setEmailError('Please fill in this');
                setIsEmail(false)
            }
            else if (invalidCharsRegex.test(email)) {
                setEmailError("invalid special characters")
            }
            else if (!gmailRegex.test(email)) {
                setEmailError("Hmm... that email doesn't look right");
                setIsEmail(false)
            } else {
                setIsEmail(true);
            }
        }
        else if (isName && !isMobileNumber) {
            if (mobileNumber === "") {
                setMobileNumber('Please fill in this');
            }
            if (mobileNumber.length < 10) {
                setMobileNumberError("Hmm... that mobile number doesn't look right");
            } else {
                setIsMobileNumber(true);
            }
        }
        else if (!isName) {
            if (!name.trim()) {
                setNameError('Please fill in this')
            }
            else if (invalidCharsRegex.test(name)) {
                setNameError("invalid special characters")
            }
            else {
                setIsName(true)
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API.BASE_URL}${API.MEETING_FORM}`,
                {
                    name: name,
                    email: email,
                    countryCode: selectedCountry,
                    mobileNumber: mobileNumber,
                    socialMedia: socialMedia,
                    appointmentType: appointmentType,
                    agenda: agenda,
                    duration: duration,
                    eventName: eventName,
                    institutionName: institutionName,
                    date: date,
                    agenda1: agenda1,
                    audienceType: audienceType,
                    audienceNumber: audienceNumber,
                    guest: guest,
                    linkedin: linkedin,
                    paid: paid,
                    mode: mode,
                    location: location
                });
                console.log(response.data)
            if (response?.data?.statusCode === 200) {
                setIsResponse(true);
                setName('');
                setEmail('');
                setSelectedCountry('');
                setMobileNumber('');
                setSocialMedia('');
                setAppointmentType('');
                setAgenda('');
                setDuration('');
                setEventName('');
                setInstitutionName('');
                setDate('');
                setAgenda1('');
                setAudienceType('');
                setAudienceNumber('');
                setGuest('');
                setLinkedin('');
                setPaid('');
                setMode('');
                setLocation('');
            }
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <div className='relative h-screen'>
            {isResponse && (
                <>
                    <div className='fixed w-full h-screen overflow-hidden bg-[#00000099] z-[1] inset-0'></div>
                    <div className='absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] bg-[#FFF] w-full max-w-[300px] sm:max-w-[500px] xl:max-w-[700px] h-full max-h-[400px] xl:max-h-[600px] rounded-[30px] z-[2] flex flex-col gap-[10px] justify-center items-center'>
                        <img src={Logo} alt='logo' className='absolute z-0 w-[40%] left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] opacity-10' />
                        <h1 className='text-[#666666] text-[22px] sm:text-[30px] font-bold'>Your Form Has</h1>
                        <h1 className='text-[#666666] text-[22px] sm:text-[30px] font-bold'>Been <span className='text-[#0142ac]'>Successfully</span></h1>
                        <h1 className='text-[#666666] text-[22px] sm:text-[30px] font-bold'>Submitted</h1>
                        <FaThumbsUp className='text-[#000] text-[22px] sm:text-[30px] font-bold' />
                        <button className='bg-[#0142ac] p-3 mt-[40px] xl:mt-[70px] rounded-md text-[#FFF] text-[16px] sm:text-[24px] xl:text-[30px] font-semibold cursor-pointer z-[1]' onClick={() => window.location.href = 'https://www.pepul.com'}>Click Here</button>
                    </div>
                </>
            )}
            <RxCross1 className="text-[#FFF] absolute top-3 right-3 w-6 h-6" />
            <img src={Logo} alt='logo' className='fixed z-0 w-[40%] left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] opacity-20' />
            <div class="w-full bg-[#0144ac4d] rounded-full h-[4px] absolute start-0 top-0">
                <div class={`bg-[#0144ac] h-full rounded-full transition-all duration-200 
                    ${duration ? "w-full" :
                        location ? "w-[93.75%]" :
                            mode === "Online" ? "w-[93.3333%]" :
                                mode === "Offline" ? "w-[87.5%]" :
                                    paid ? "w-[92.8571%]" :
                                        linkedin ? "w-[85.7143%]" :
                                            guest ? "w-[82%]" :
                                                audienceNumber ? "w-[78.5714%]" :
                                                    audienceType ? "w-[71.4286%]" :
                                                        ((appointmentType === "Invite as Chief Guest" || appointmentType === "Invite as Panel Judge" || appointmentType === "Invite as speaker") && agenda) ? "w-[64.2857%]" :
                                                            date ? "w-[57.1429%]" :
                                                                institutionName ? "w-[50%]" :
                                                                    eventName ? "w-[42.8571%]" :
                                                                        ((appointmentType === "1:1 Business Proposal" || appointmentType === "1:1 Casual Meet" || appointmentType === "Other") && duration) ? "w-full" :
                                                                            ((appointmentType === "1:1 Business Proposal" || appointmentType === "1:1 Casual Meet" || appointmentType === "Other") && agenda) ? "w-[85.7143%]" :
                                                                                (appointmentType === "Invite as Chief Guest" || appointmentType === "Invite as Panel Judge" || appointmentType === "Invite as speaker") ? "w-[35.7143%]" :
                                                                                    (appointmentType === "1:1 Business Proposal" || appointmentType === "1:1 Casual Meet" || appointmentType === "Other") ? "w-[71.4286%]" :
                                                                                        socialMedia ? "w-[80%]" :
                                                                                            email ? "w-[60%]" :
                                                                                                name ? "w-[20%]" : "w-0"}`}>
                </div>
            </div>
            <div className='relative h-screen w-full'>
                <div className='flex flex-col justify-start items-center h-full overflow-y-hidden' >
                    {/* ------------- Name ------------- */}
                    <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                        ${isName ? "opacity-0 -translate-y-full" : ""}`
                    }>
                        <div className='relative'>
                            <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>1</h1>
                                <FaArrowRight className='text-[#666666] flex shrink-0 ' />
                            </div>
                            <div className='text-[#000000]'>
                                <h1 className='text-xl sm:text-2xl font-normal'>Hi! What is Your Name ?*</h1>
                            </div>
                            <div className='mt-[20px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px]'>
                                <input type='text' value={name} placeholder='Type your answer here...' className='max-w-full w-full bg-transparent border-none pb-2 placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#666666] placeholder:text-[#d1d1d1] focus:outline-none focus:shadow-focus-meeting-form shadow-focus-meeting-form cursor-pointer' onChange={(e) => { setName(e.target.value); setNameError('') }} />
                            </div>
                            {nameError ?
                                (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                            <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                            <h1 className='text-[#af0404] text-sm'>{nameError}</h1>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleNameSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                    </div>
                                )}
                        </div>
                    </div>
                    {/* ------------- Phone Number ------------- */}
                    <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                        ${isMobileNumber ? "opacity-0 -translate-y-[200%]" :
                            isName ? "opacity-1 -translate-y-full" : ""}`
                    }>
                        <div className='relative'>
                            <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>2</h1>
                                <FaArrowRight className='text-[#666666] flex shrink-0' />
                            </div>
                            <div className='text-[#000000]'>
                                <h1 className='text-xl sm:text-2xl font-normal'>Contact Number*</h1>
                            </div>
                            <PhoneInput country={"us"} value={mobileNumber} onChange={(phone, country) => {setMobileNumber(phone);setSelectedCountry(country?.country?.dialCode);setMobileNumberError('');}} className="max-h-[40px] sm:max-h-[60px] h-full mt-[20px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px] focus:outline-none meeting-form cursor-pointer" />
                            {mobileNumberError ?
                                (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                            <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                            <h1 className='text-[#af0404] text-sm'>{mobileNumberError}</h1>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleMobileNumberSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                    </div>
                                )}
                        </div>
                    </div>
                    {/* ------------- Email ------------- */}
                    <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                        ${isEmail ? "opacity-0 -translate-y-[300%]" :
                            isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                isName ? "opacity-1 -translate-y-full" : ""}`
                    }>
                        <div className='relative'>
                            <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>3</h1>
                                <FaArrowRight className='text-[#666666] flex shrink-0' />
                            </div>
                            <div className='text-[#000000]'>
                                <h1 className='text-xl sm:text-2xl font-normal'>Email Address*</h1>
                            </div>
                            <div className='mt-[20px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px]'>
                                <input type='email' value={email} placeholder='name@example.com' className='max-w-full w-full bg-transparent border-none pb-2 placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#666666] placeholder:text-[#d1d1d1] focus:outline-none focus:shadow-focus-meeting-form shadow-focus-meeting-form cursor-pointer' onChange={(e) => { setEmail(e.target.value); setEmailError('') }} />
                            </div>
                            {emailError ?
                                (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                            <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                            <h1 className='text-[#af0404] text-sm'>{emailError}</h1>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleEmailSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                    </div>
                                )}
                        </div>
                    </div>
                    {/* ------------- Social Media ------------- */}
                    <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                        ${isSocialMedia ? "opacity-0 -translate-y-[400%]" :
                            isEmail ? "opacity-1 -translate-y-[300%]" :
                                isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                    isName ? "opacity-1 -translate-y-full" : ""}`
                    }>
                        <div className='relative'>
                            <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>4</h1>
                                <FaArrowRight className='text-[#666666] flex shrink-0' />
                            </div>
                            <div className='text-[#000000]'>
                                <h1 className='text-xl sm:text-2xl font-normal'>Please share your Social Media profile link.*</h1>
                            </div>
                            <div className='mt-[20px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px]'>
                                <input type='text' value={socialMedia} placeholder='https://' className='max-w-full w-full bg-transparent border-none pb-2 placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#666666] placeholder:text-[#d1d1d1] focus:outline-none focus:shadow-focus-meeting-form shadow-focus-meeting-form cursor-pointer' onChange={(e) => { setSocialMedia(e.target.value); setSocialMediaError('') }} />
                            </div>
                            {socialMediaError ?
                                (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                            <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                            <h1 className='text-[#af0404] text-sm'>{socialMediaError}</h1>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='mt-[16px] w-full flex gap-4 items-center'>
                                        <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleSocialMediaSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                    </div>
                                )}
                        </div>
                    </div>
                    {/* ------------- Appointment Type ------------- */}
                    <div className={`flex flex-col justify-center sm:p-0 w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                        ${isAppointmentType ? "opacity-0 -translate-y-[500%]" :
                            isSocialMedia ? "opacity-1 -translate-y-[400%]" :
                                isEmail ? "opacity-1 -translate-y-[300%]" :
                                    isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                        isName ? "opacity-1 -translate-y-full" : ""}`
                    }>
                        <div className='relative'>
                            <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>5</h1>
                                <FaArrowRight className='text-[#666666] flex shrink-0' />
                            </div>
                            <div className='text-[#000000]'>
                                <h1 className='text-xl sm:text-2xl font-normal'>Appointment Request Type?*</h1>
                                <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                            </div>
                            <div className='mt-[32px] w-full flex flex-col max-w-full min-w-[168px] items-stretch'>
                                <div className={`mb-2 w-[84%] sm:w-[52%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${appointmentType === "One to One Business Proposal" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleAppointmentType("One to One Business Proposal"); setAppointmentTypeError('') }}>
                                    <div className={`my-1 mx-2 w-7 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699]  border-[1px] flex justify-center items-center ${appointmentType === "One to One Business Proposal" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                        <span className='font-bold'>A</span>
                                    </div>
                                    <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>One to One Business Proposal</h1>
                                    {appointmentType === "One to One Business Proposal" && (
                                        <IoMdCheckmark className='text-[#666666] my-auto text-end text-[20px] sm:text-[24px] mr-2' />
                                    )}
                                </div>
                                <div className={`mb-2 w-[84%] sm:w-[52%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${appointmentType === "One to One Casual Meet" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleAppointmentType("One to One Casual Meet"); setAppointmentTypeError('') }}>
                                    <div className={`my-1 mx-2 w-7 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699] border-[1px] flex justify-center items-center ${appointmentType === "One to One Casual Meet" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                        <span className='font-bold'>B</span>
                                    </div>
                                    <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>One to One Casual Meet</h1>
                                    {appointmentType === "One to One Casual Meet" && (
                                        <IoMdCheckmark className='text-[#666666] my-auto text-end text-[20px] sm:text-[24px] mr-2' />
                                    )}
                                </div>
                                <div className={`mb-2 w-[84%] sm:w-[52%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${appointmentType === "Invite as Chief Guest" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleAppointmentType("Invite as Chief Guest"); setAppointmentTypeError('') }}>
                                    <div className={`my-1 mx-2 w-7 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699] border-[1px] flex justify-center items-center ${appointmentType === "Invite as Chief Guest" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                        <span className='font-bold'>C</span>
                                    </div>
                                    <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] ttext-[#666666] my-auto'>Invite as Chief Guest</h1>
                                    {appointmentType === "Invite as Chief Guest" && (
                                        <IoMdCheckmark className='text-[#666666] my-auto text-end text-[20px] sm:text-[24px] mr-2' />
                                    )}
                                </div>
                                <div className={`mb-2 w-[84%] sm:w-[52%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${appointmentType === "Invite as Panel Judge" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleAppointmentType("Invite as Panel Judge"); setAppointmentTypeError('') }}>
                                    <div className={`my-1 mx-2 w-7 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699] border-[1px] flex justify-center items-center ${appointmentType === "Invite as Panel Judge" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                        <span className='font-bold'>D</span>
                                    </div>
                                    <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] ttext-[#666666] my-auto'>Invite as Panel Judge</h1>
                                    {appointmentType === "Invite as Panel Judge" && (
                                        <IoMdCheckmark className='text-[#666666] my-auto text-end text-[20px] sm:text-[24px] mr-2' />
                                    )}
                                </div>
                                <div className={`mb-2 w-[84%] sm:w-[52%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${appointmentType === "Invite as speaker" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleAppointmentType("Invite as speaker"); setAppointmentTypeError('') }}>
                                    <div className={`my-1 mx-2 w-7 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699] border-[1px] flex justify-center items-center ${appointmentType === "Invite as speaker" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                        <span className='font-bold'>E</span>
                                    </div>
                                    <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] ttext-[#666666] my-auto'>Invite as speaker</h1>
                                    {appointmentType === "Invite as speaker" && (
                                        <IoMdCheckmark className='text-[#666666] my-auto text-end text-[20px] sm:text-[24px] mr-2' />
                                    )}
                                </div>
                                <div className={`mb-2 w-[84%] sm:w-[52%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${appointmentType === "Other" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleAppointmentType("Other"); setAppointmentTypeError('') }}>
                                    <div className={`my-1 mx-2 w-7 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699] border-[1px] flex justify-center items-center ${appointmentType === "Other" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                        <span className='font-bold'>F</span>
                                    </div>
                                    <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>Other</h1>
                                    {appointmentType === "Other" && (
                                        <IoMdCheckmark className='text-[#666666] my-auto text-end text-[20px] sm:text-[24px] mr-2' />
                                    )}
                                </div>
                            </div>
                            {appointmentType && appointmentTypeError === "" && (
                                <div className='mt-[16px] w-full flex gap-4 items-center'>
                                    <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleAppointmentTypeSubmit}>
                                        <h1>OK</h1><MdDone className='text-xl' />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    {appointmentType === "One to One Business Proposal" || appointmentType === "One to One Casual Meet" || appointmentType === "Other" ? (
                        <>
                            {/* ------------- Agenda ------------- */}
                            <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                ${isAgenda ? "opacity-0 -translate-y-[600%]" :
                                    isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                        isSocialMedia ? "opacity-0 -translate-y-[400%]" :
                                            isEmail ? "opacity-1 -translate-y-[300%]" :
                                                isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                    isName ? "opacity-1 -translate-y-full" : ""}`
                            }>
                                <div className='relative'>
                                    <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                        <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>6</h1>
                                        <FaArrowRight className='text-[#666666] flex shrink-0' />
                                    </div>
                                    <div className='text-[#000000]'>
                                        <h1 className='text-xl sm:text-2xl font-normal'>Meeting Agenda?*</h1>
                                        <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                    </div>
                                    <div className='mt-[20px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px]'>
                                        <textarea type='text' value={agenda} placeholder='Type your answer here...' rows={1} className='relative max-w-full w-full bg-transparent border-none placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#666666] placeholder:text-[#d1d1d1] focus:outline-none focus:shadow-focus-meeting-form shadow-focus-meeting-form resize-none cursor-pointer' onChange={(e) => { setAgenda(e.target.value); setAgendaError('') }} />
                                    </div>
                                    {agendaError ?
                                        (
                                            <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                                    <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                                    <h1 className='text-[#af0404] text-sm'>{agendaError}</h1>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleAgendaSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                            </div>
                                        )}
                                </div>
                            </div>
                            {/* ------------- Duration ------------- */}
                            <div className={`flex flex-col justify-center sm:p-0 w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                ${isDuration ? "opacity-0 -translate-y-[700%]" :
                                    isAgenda ? "opacity-1 -translate-y-[600%]" :
                                        isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                            isSocialMedia ? "opacity-1 -translate-y-[400%]" :
                                                isEmail ? "opacity-1 -translate-y-[300%]" :
                                                    isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                        isName ? "opacity-1 -translate-y-full" : ""}`
                            }>
                                <div className='relative'>
                                    <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                        <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>7</h1>
                                        <FaArrowRight className='text-[#666666] flex shrink-0' />
                                    </div>
                                    <div className='text-[#000000]'>
                                        <h1 className='text-xl sm:text-2xl font-normal'>Meeting Duration?*</h1>
                                        <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                    </div>
                                    <div className='mt-[32px] w-full flex flex-col max-w-full min-w-[168px] items-stretch'>
                                        <div className={`mb-2 w-[64%] sm:w-[28%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form  ${duration === "30 Mins" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleDuration("30 Mins"); setDurationError('') }}>
                                            <div className={`my-1 mx-2 w-6 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699]  border-[1px] flex shrink-0 justify-center items-center ${duration === "30 Mins" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                                <span className='font-bold'>A</span>
                                            </div>
                                            <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>30 Mins</h1>
                                            {duration === "30 Mins" && (
                                                <IoMdCheckmark className='text-[#666666] my-auto text-end text-[32px] sm:text-[24px] mr-2' />
                                            )}
                                        </div>
                                        <div className={`mb-2 w-[64%] sm:w-[28%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${duration === "45 Mins" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleDuration("45 Mins"); setDurationError('') }}>
                                            <div className={`my-1 mx-2 w-6 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699] border-[1px] flex shrink-0 justify-center items-center ${duration === "45 Mins" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                                <span className='font-bold'>B</span>
                                            </div>
                                            <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>45 Mins</h1>
                                            {duration === "45 Mins" && (
                                                <IoMdCheckmark className='text-[#666666] my-auto text-end text-[32px] sm:text-[24px] mr-2' />
                                            )}
                                        </div>
                                        <div className={`mb-2 w-[64%] sm:w-[28%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${duration === "1 Hour" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleDuration("1 Hour"); setDurationError('') }}>
                                            <div className={`my-1 mx-2 w-6 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699] border-[1px] flex shrink-0 justify-center items-center ${duration === "1 Hour" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                                <span className='font-bold'>C</span>
                                            </div>
                                            <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>1 Hour</h1>
                                            {duration === "1 Hour" && (
                                                <IoMdCheckmark className='text-[#666666] my-auto text-end text-[32px] sm:text-[24px] mr-2' />
                                            )}
                                        </div>
                                    </div>
                                    {duration && durationError === "" && (
                                        <div className='mt-[16px] w-full flex gap-4 items-center'>
                                            <button type='button' className='min-h-10 py-1.5 px-3.5 w-full sm:w-auto bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleSubmit}>
                                                <h1 className='mx-auto'>Submit</h1>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    ) :
                        appointmentType === "Invite as Chief Guest" || appointmentType === "Invite as Panel Judge" || appointmentType === "Invite as speaker" ? (
                            <>
                                {/* ------------- Event Name ------------- */}
                                <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                ${isEventName ? "opacity-0 -translate-y-[600%]" :
                                        isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                            isSocialMedia ? "opacity-0 -translate-y-[400%]" :
                                                isEmail ? "opacity-1 -translate-y-[300%]" :
                                                    isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                        isName ? "opacity-1 -translate-y-full" : ""}`
                                }>
                                    <div className='relative'>
                                        <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                            <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>6</h1>
                                            <FaArrowRight className='text-[#666666] flex shrink-0' />
                                        </div>
                                        <div className='text-[#000000]'>
                                            <h1 className='text-xl sm:text-2xl font-normal'>Event Name?*</h1>
                                            <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                        </div>
                                        <div className='mt-[20px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px]'>
                                            <input type='text' value={eventName} placeholder='Type your answer here...' className='relative max-w-full w-full bg-transparent border-none placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#666666] placeholder:text-[#d1d1d1] focus:outline-none focus:shadow-focus-meeting-form shadow-focus-meeting-form cursor-pointer' onChange={(e) => { setEventName(e.target.value); setEventNameError('') }} />
                                        </div>
                                        {eventNameError ?
                                            (
                                                <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                    <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                                        <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                                        <h1 className='text-[#af0404] text-sm'>{eventName}</h1>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                    <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleEventNameSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                {/* ------------- Institution Name ------------- */}
                                <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                ${isInstitutionName ? "opacity-0 -translate-y-[700%]" :
                                        isEventName ? "opacity-1 -translate-y-[600%]" :
                                            isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                                isSocialMedia ? "opacity-0 -translate-y-[400%]" :
                                                    isEmail ? "opacity-1 -translate-y-[300%]" :
                                                        isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                            isName ? "opacity-1 -translate-y-full" : ""}`
                                }>
                                    <div className='relative'>
                                        <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                            <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>7</h1>
                                            <FaArrowRight className='text-[#666666] flex shrink-0' />
                                        </div>
                                        <div className='text-[#000000]'>
                                            <h1 className='text-xl sm:text-2xl font-normal'>Institution/College Name?*</h1>
                                            <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                        </div>
                                        <div className='mt-[20px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px]'>
                                            <input type='text' value={institutionName} placeholder='Type your answer here...' className='relative max-w-full w-full bg-transparent border-none placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#666666] placeholder:text-[#d1d1d1] focus:outline-none focus:shadow-focus-meeting-form shadow-focus-meeting-form cursor-pointer' onChange={(e) => { setInstitutionName(e.target.value); setInstitutionNameError('') }} />
                                        </div>
                                        {institutionNameError ?
                                            (
                                                <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                    <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                                        <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                                        <h1 className='text-[#af0404] text-sm'>{institutionNameError}</h1>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                    <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleInstitutionNameSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                {/* ------------- Date ------------- */}
                                <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                ${isDate ? "opacity-0 -translate-y-[800%]" :
                                        isInstitutionName ? "opacity-1 -translate-y-[700%]" :
                                            isEventName ? "opacity-1 -translate-y-[600%]" :
                                                isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                                    isSocialMedia ? "opacity-0 -translate-y-[400%]" :
                                                        isEmail ? "opacity-1 -translate-y-[300%]" :
                                                            isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                                isName ? "opacity-1 -translate-y-full" : ""}`
                                }>
                                    <div className='relative'>
                                        <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                            <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>8</h1>
                                            <FaArrowRight className='text-[#666666] flex shrink-0' />
                                        </div>
                                        <div className='text-[#000000]'>
                                            <h1 className='text-xl sm:text-2xl font-normal'>Event Date & Time?*</h1>
                                            <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                        </div>
                                        <div className='mt-[20px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px]'>
                                            {/* <input type='text' value={date} placeholder='Type your answer here...' className='relative max-w-full w-full bg-transparent border-none placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#666666] placeholder:text-[#d1d1d1] focus:outline-none focus:shadow-focus-meeting-form shadow-focus-meeting-form' onChange={(e) => { setDate(e.target.value); setDateError(false) }} /> */}
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DateTimePicker']}>
                                                    <DateTimePicker
                                                        label="Select a Date And Times"
                                                        onChange={handleDateChange}
                                                        value={date}
                                                        disablePast
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                        {dateError ?
                                            (
                                                <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                    <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                                        <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                                        <h1 className='text-[#af0404] text-sm'>Please select a Date & Time</h1>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                    <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleDateSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                {/* ------------- Agenda ------------- */}
                                <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                ${isAgenda1 ? "opacity-0 -translate-y-[900%]" :
                                        isDate ? "opacity-1 -translate-y-[800%]" :
                                            isInstitutionName ? "opacity-1 -translate-y-[700%]" :
                                                isEventName ? "opacity-1 -translate-y-[600%]" :
                                                    isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                                        isSocialMedia ? "opacity-1 -translate-y-[400%]" :
                                                            isEmail ? "opacity-1 -translate-y-[300%]" :
                                                                isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                                    isName ? "opacity-1 -translate-y-full" : ""}`
                                }>
                                    <div className='relative'>
                                        <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                            <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>9</h1>
                                            <FaArrowRight className='text-[#666666] flex shrink-0 ml-0.5 sm:ml-1 lg:ml-0' />
                                        </div>
                                        <div className='text-[#000000]'>
                                            <h1 className='text-xl sm:text-2xl font-normal'>Event Agenda?*</h1>
                                            <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                        </div>
                                        <div className='mt-[20px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px]'>
                                            <textarea type='text' value={agenda1} placeholder='Type your answer here...' rows={1} className='relative max-w-full w-full bg-transparent border-none placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#666666] placeholder:text-[#d1d1d1] focus:outline-none focus:shadow-focus-meeting-form shadow-focus-meeting-form resize-none cursor-pointer' onChange={(e) => { setAgenda1(e.target.value); setAgenda1Error('') }} />
                                        </div>
                                        {agenda1Error ?
                                            (
                                                <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                    <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                                        <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                                        <h1 className='text-[#af0404] text-sm'>{agenda1Error}</h1>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                    <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleAgenda1Submit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                {/* ------------- Audience Type ------------- */}
                                <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                ${isAudienceType ? "opacity-0 -translate-y-[1000%]" :
                                        isAgenda1 ? "opacity-1 -translate-y-[900%]" :
                                            isDate ? "opacity-1 -translate-y-[800%]" :
                                                isInstitutionName ? "opacity-1 -translate-y-[700%]" :
                                                    isEventName ? "opacity-1 -translate-y-[600%]" :
                                                        isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                                            isSocialMedia ? "opacity-1 -translate-y-[400%]" :
                                                                isEmail ? "opacity-1 -translate-y-[300%]" :
                                                                    isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                                        isName ? "opacity-1 -translate-y-full" : ""}`
                                }>
                                    <div className='relative'>
                                        <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                            <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px] '>10</h1>
                                            <FaArrowRight className='text-[#666666] flex shrink-0' />
                                        </div>
                                        <div className='text-[#000000]'>
                                            <h1 className='text-xl sm:text-2xl font-normal'>Type of Audience?*</h1>
                                            <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                        </div>
                                        <div className='mt-[20px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px]'>
                                            <input type='text' value={audienceType} placeholder='Type your answer here...' className='relative max-w-full w-full bg-transparent border-none placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#666666] placeholder:text-[#d1d1d1] focus:outline-none focus:shadow-focus-meeting-form shadow-focus-meeting-form resize-none cursor-pointer' onChange={(e) => { setAudienceType(e.target.value); setAudienceTypeError('') }} />
                                        </div>
                                        {audienceTypeError ?
                                            (
                                                <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                    <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                                        <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                                        <h1 className='text-[#af0404] text-sm'>{audienceTypeError}</h1>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                    <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleAudienceTypeSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                {/* ------------- Audience Number ------------- */}
                                <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                ${isAudienceNumber ? "opacity-0 -translate-y-[1100%]" :
                                        isAudienceType ? "opacity-1 -translate-y-[1000%]" :
                                            isAgenda1 ? "opacity-1 -translate-y-[900%]" :
                                                isDate ? "opacity-1 -translate-y-[800%]" :
                                                    isInstitutionName ? "opacity-1 -translate-y-[700%]" :
                                                        isEventName ? "opacity-1 -translate-y-[600%]" :
                                                            isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                                                isSocialMedia ? "opacity-1 -translate-y-[400%]" :
                                                                    isEmail ? "opacity-1 -translate-y-[300%]" :
                                                                        isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                                            isName ? "opacity-1 -translate-y-full" : ""}`
                                }>
                                    <div className='relative'>
                                        <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                            <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px] '>11</h1>
                                            <FaArrowRight className='text-[#666666] flex shrink-0' />
                                        </div>
                                        <div className='text-[#000000]'>
                                            <h1 className='text-xl sm:text-2xl font-normal'>Expected Number of Audience?*</h1>
                                            <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                        </div>
                                        <div className='mt-[20px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px]'>
                                            <input type='text' value={audienceNumber} placeholder='Type your answer here...' className='relative max-w-full w-full bg-transparent border-none placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#666666] placeholder:text-[#d1d1d1] focus:outline-none focus:shadow-focus-meeting-form shadow-focus-meeting-form cursor-pointer' onChange={(e) => { setAudienceNumber(e.target.value); setAudienceNumberError('') }} />
                                        </div>
                                        {audienceNumberError ?
                                            (
                                                <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                    <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                                        <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                                        <h1 className='text-[#af0404] text-sm'>{audienceNumberError}</h1>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                    <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleAudienceNumberSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                {/* ------------- Guest ------------- */}
                                <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                ${isGuest ? "opacity-0 -translate-y-[1200%]" :
                                        isAudienceNumber ? "opacity-1 -translate-y-[1100%]" :
                                            isAudienceType ? "opacity-1 -translate-y-[1000%]" :
                                                isAgenda1 ? "opacity-1 -translate-y-[900%]" :
                                                    isDate ? "opacity-1 -translate-y-[800%]" :
                                                        isInstitutionName ? "opacity-1 -translate-y-[700%]" :
                                                            isEventName ? "opacity-1 -translate-y-[600%]" :
                                                                isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                                                    isSocialMedia ? "opacity-1 -translate-y-[400%]" :
                                                                        isEmail ? "opacity-1 -translate-y-[300%]" :
                                                                            isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                                                isName ? "opacity-1 -translate-y-full" : ""}`
                                }>
                                    <div className='relative'>
                                        <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                            <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>12</h1>
                                            <FaArrowRight className='text-[#666666] flex shrink-0' />
                                        </div>
                                        <div className='text-[#000000]'>
                                            <h1 className='text-xl sm:text-2xl font-normal'>Is there any other guests attending?*</h1>
                                            <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                        </div>
                                        <div className='mt-[32px] w-full flex flex-col max-w-full min-w-[168px] items-stretch'>
                                            <div className={`mb-2 w-[64%] sm:w-[28%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${guest === "Yes" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleGuest("Yes"); setGuestError('') }}>
                                                <div className={`my-1 mx-2 w-6 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699]  border-[1px] flex shrink-0 justify-center items-center ${guest === "Yes" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                                    <span className='font-bold'>Y</span>
                                                </div>
                                                <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>Yes</h1>
                                                {guest === "Yes" && (
                                                    <IoMdCheckmark className='text-[#666666] my-auto text-end text-[32px] sm:text-[24px] mr-2' />
                                                )}
                                            </div>
                                            <div className={`mb-2 w-[64%] sm:w-[28%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${guest === "No" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleGuest("No"); setGuestError('') }}>
                                                <div className={`my-1 mx-2 w-6 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699] border-[1px] flex shrink-0 justify-center items-center ${guest === "No" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                                    <span className='font-bold'>N</span>
                                                </div>
                                                <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>No</h1>
                                                {guest === "No" && (
                                                    <IoMdCheckmark className='text-[#666666] my-auto text-end text-[32px] sm:text-[24px] mr-2' />
                                                )}
                                            </div>
                                        </div>
                                        {guestError !== "" ? (
                                            <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                                    <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                                    <h1 className='text-[#af0404] text-sm'>{guestError}</h1>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleGuestSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {guest === "Yes" && (
                                    <>
                                        {/* ------------- Likedin Profile ------------- */}
                                        <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                        ${isLinkedin ? "opacity-0 -translate-y-[1300%]" :
                                                isGuest ? "opacity-1 -translate-y-[1200%]" :
                                                    isAudienceNumber ? "opacity-1 -translate-y-[1100%]" :
                                                        isAudienceType ? "opacity-1 -translate-y-[1000%]" :
                                                            isAgenda1 ? "opacity-1 -translate-y-[900%]" :
                                                                isDate ? "opacity-1 -translate-y-[800%]" :
                                                                    isInstitutionName ? "opacity-1 -translate-y-[700%]" :
                                                                        isEventName ? "opacity-1 -translate-y-[600%]" :
                                                                            isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                                                                isSocialMedia ? "opacity-1 -translate-y-[400%]" :
                                                                                    isEmail ? "opacity-1 -translate-y-[300%]" :
                                                                                        isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                                                            isName ? "opacity-1 -translate-y-full" : ""}`
                                        }>
                                            <div className='relative'>
                                                <div className='flex items-center mr-2 absolute end-full  mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                                    <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px] '>13</h1>
                                                    <FaArrowRight className='text-[#666666] flex shrink-0' />
                                                </div>
                                                <div className='text-[#000000]'>
                                                    <h1 className='text-xl sm:text-2xl font-normal'> If so, please share their LinkedIn profile*</h1>
                                                    <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                                </div>
                                                <div className='mt-[20px] w-full max-w-[280px] sm:max-w-[530px] lg:max-w-[720px]'>
                                                    <input type='text' value={linkedin} placeholder='https://' className='max-w-full w-full bg-transparent border-none pb-2 placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#666666] placeholder:text-[#d1d1d1] focus:outline-none focus:shadow-focus-meeting-form shadow-focus-meeting-form cursor-pointer' onChange={(e) => { setLinkedin(e.target.value); setLinkedinError('') }} />
                                                </div>
                                                {linkedinError ?
                                                    (
                                                        <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                            <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                                                <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                                                <h1 className='text-[#af0404] text-sm'>{linkedinError}</h1>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                            <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleLinkedinSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )}
                                {/* ------------- Paid ------------- */}
                                <div className={`flex flex-col justify-center sm:p-0 w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                ${!isLinkedin && isPaid ? "opacity-0 -translate-y-[1300%]" :
                                        isLinkedin && isPaid ? "opacity-0 -translate-y-[1400%]" :
                                            isLinkedin ? "opacity-1 -translate-y-[1300%]" :
                                                isGuest ? "opacity-1 -translate-y-[1200%]" :
                                                    isAudienceNumber ? "opacity-1 -translate-y-[1100%]" :
                                                        isAudienceType ? "opacity-1 -translate-y-[1000%]" :
                                                            isAgenda1 ? "opacity-1 -translate-y-[900%]" :
                                                                isDate ? "opacity-1 -translate-y-[800%]" :
                                                                    isInstitutionName ? "opacity-1 -translate-y-[700%]" :
                                                                        isEventName ? "opacity-1 -translate-y-[600%]" :
                                                                            isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                                                                isSocialMedia ? "opacity-1 -translate-y-[400%]" :
                                                                                    isEmail ? "opacity-1 -translate-y-[300%]" :
                                                                                        isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                                                            isName ? "opacity-1 -translate-y-full" : ""}`
                                }>
                                    <div className='relative'>
                                        <div className='flex items-center mr-2 absolute end-full mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                            <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>{guest === "Yes" ? 14 : 13}</h1>
                                            <FaArrowRight className='text-[#666666] flex shrink-0' />
                                        </div>
                                        <div className='text-[#000000]'>
                                            <h1 className='text-xl sm:text-2xl font-normal'>Is it a paid event? Note:- We never do paid event, We do only free events to give back to society*</h1>
                                            <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                        </div>
                                        <div className='mt-[32px] w-full flex flex-col max-w-full min-w-[168px] items-stretch'>
                                            <div className={`mb-2 w-[64%] sm:w-[28%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${paid === "Yes" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handlePaid("Yes"); setPaidError('') }}>
                                                <div className={`my-1 mx-2 w-6 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699]  border-[1px] flex shrink-0 justify-center items-center ${paid === "Yes" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                                    <span className='font-bold'>Y</span>
                                                </div>
                                                <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>Yes</h1>
                                                {paid === "Yes" && (
                                                    <IoMdCheckmark className='text-[#666666] my-auto text-end text-[32px] sm:text-[24px] mr-2' />
                                                )}
                                            </div>
                                            <div className={`mb-2 w-[64%] sm:w-[28%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${paid === "No" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handlePaid("No"); setPaidError('') }}>
                                                <div className={`my-1 mx-2 w-6 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699] border-[1px] flex shrink-0 justify-center items-center ${paid === "No" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                                    <span className='font-bold'>N</span>
                                                </div>
                                                <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>No</h1>
                                                {paid === "No" && (
                                                    <IoMdCheckmark className='text-[#666666] my-auto text-end text-[32px] sm:text-[24px] mr-2' />
                                                )}
                                            </div>
                                        </div>
                                        {paidError !== "" ? (
                                            <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                                    <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                                    <h1 className='text-[#af0404] text-sm'>{paidError}</h1>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handlePaidSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* ------------- Mode ------------- */}
                                <div className={`flex flex-col justify-center sm:p-0 w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                ${!isLinkedin && isMode ? "opacity-0 -translate-y-[1400%]" :
                                        isLinkedin && isMode ? "opacity-0 -translate-y-[1500%]" :
                                            !isLinkedin && isPaid ? "opacity-1 -translate-y-[1300%]" :
                                                isLinkedin && isPaid ? "opacity-1 -translate-y-[1400%]" :
                                                    isLinkedin ? "opacity-1 -translate-y-[1300%]" :
                                                        isGuest ? "opacity-1 -translate-y-[1200%]" :
                                                            isAudienceNumber ? "opacity-1 -translate-y-[1100%]" :
                                                                isAudienceType ? "opacity-1 -translate-y-[1000%]" :
                                                                    isAgenda1 ? "opacity-1 -translate-y-[900%]" :
                                                                        isDate ? "opacity-1 -translate-y-[800%]" :
                                                                            isInstitutionName ? "opacity-1 -translate-y-[700%]" :
                                                                                isEventName ? "opacity-1 -translate-y-[600%]" :
                                                                                    isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                                                                        isSocialMedia ? "opacity-1 -translate-y-[400%]" :
                                                                                            isEmail ? "opacity-1 -translate-y-[300%]" :
                                                                                                isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                                                                    isName ? "opacity-1 -translate-y-full" : ""}`
                                }>
                                    <div className='relative'>
                                        <div className='flex items-center mr-2 absolute end-full mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                            <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>{guest === "Yes" ? 15 : 14}</h1>
                                            <FaArrowRight className='text-[#666666] flex shrink-0' />
                                        </div>
                                        <div className='text-[#000000]'>
                                            <h1 className='text-xl sm:text-2xl font-normal'>Mode of Meeting?*</h1>
                                            <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                        </div>
                                        <div className='mt-[32px] w-full flex flex-col max-w-full min-w-[168px] items-stretch'>
                                            <div className={`mb-2 w-[64%] sm:w-[28%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${mode === "Offline" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleMode("Offline"); setModeError('') }}>
                                                <div className={`my-1 mx-2 w-6 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699]  border-[1px] flex shrink-0 justify-center items-center ${mode === "Offline" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                                    <span className='font-bold'>A</span>
                                                </div>
                                                <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>Offline</h1>
                                                {mode === "Offline" && (
                                                    <IoMdCheckmark className='text-[#666666] my-auto text-end text-[32px] sm:text-[24px] mr-2' />
                                                )}
                                            </div>
                                            <div className={`mb-2 w-[64%] sm:w-[28%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${mode === "Online" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleMode("Online"); setModeError('') }}>
                                                <div className={`my-1 mx-2 w-6 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699] border-[1px] flex shrink-0 justify-center items-center ${mode === "Online" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                                    <span className='font-bold'>B</span>
                                                </div>
                                                <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>Online</h1>
                                                {mode === "Online" && (
                                                    <IoMdCheckmark className='text-[#666666] my-auto text-end text-[32px] sm:text-[24px] mr-2' />
                                                )}
                                            </div>
                                        </div>
                                        {modeError !== "" ? (
                                            <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                                    <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                                    <h1 className='text-[#af0404] text-sm'>{modeError}</h1>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleModeSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {mode === "Offline" && (
                                    <>
                                        {/* ------------- Location ------------- */}
                                        <div className={`flex flex-col justify-center w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                        ${!isLinkedin && isLocation ? "opacity-0 -translate-y-[1500%]" :
                                                isLinkedin && isLocation ? "opacity-0 -translate-y-[1600%]" :
                                                    !isLinkedin && isMode ? "opacity-1 -translate-y-[1400%]" :
                                                        isLinkedin && isMode ? "opacity-1 -translate-y-[1500%]" :
                                                            !isLinkedin && isPaid ? "opacity-1 -translate-y-[1300%]" :
                                                                isLinkedin && isPaid ? "opacity-1 -translate-y-[1400%]" :
                                                                    isLinkedin ? "opacity-1 -translate-y-[1300%]" :
                                                                        isGuest ? "opacity-1 -translate-y-[1200%]" :
                                                                            isAudienceNumber ? "opacity-1 -translate-y-[1100%]" :
                                                                                isAudienceType ? "opacity-1 -translate-y-[1000%]" :
                                                                                    isAgenda1 ? "opacity-1 -translate-y-[900%]" :
                                                                                        isDate ? "opacity-1 -translate-y-[800%]" :
                                                                                            isInstitutionName ? "opacity-1 -translate-y-[700%]" :
                                                                                                isEventName ? "opacity-1 -translate-y-[600%]" :
                                                                                                    isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                                                                                        isSocialMedia ? "opacity-1 -translate-y-[400%]" :
                                                                                                            isEmail ? "opacity-1 -translate-y-[300%]" :
                                                                                                                isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                                                                                    isName ? "opacity-1 -translate-y-full" : ""}`
                                        }>
                                            <div className='relative'>
                                                <div className='flex items-center mr-2 absolute end-full mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                                    <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px] ' >{guest === "Yes" ? 16 : 15}</h1>
                                                    <FaArrowRight className='text-[#666666] flex shrink-0' />
                                                </div>
                                                <div className='text-[#000000]'>
                                                    <h1 className='text-xl sm:text-2xl font-normal'>Offline Meeting location?*</h1>
                                                    <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                                </div>
                                                <div className='mt-[20px] w-full max-w-full sm:max-w-[530px] lg:max-w-[720px]'>
                                                    <input type='text' value={location} placeholder='Type your answer here...' className='relative max-w-full w-full bg-transparent border-none placeholder:text-[24px] sm:placeholder:text-[30px] text-[24px] sm:text-[30px] text-[#666666] placeholder:text-[#d1d1d1] focus:outline-none focus:shadow-focus-meeting-form shadow-focus-meeting-form cursor-pointer' onChange={(e) => { setLocation(e.target.value); setLocationError(false) }} />
                                                </div>
                                                {locationError ?
                                                    (
                                                        <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                            <div className='bg-[#f7e6e6] min-h-7 rounded-[3px] flex gap-1 items-center justify-center py-1.5 pl-2 pr-3'>
                                                                <TbAlertTriangleFilled className='text-[#af0404] w-4 h-4' />
                                                                <h1 className='text-[#af0404] text-sm'>{locationError}</h1>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                            <button type='button' className='min-h-10 py-1.5 px-3.5 bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleLocationSubmit}><h1>OK</h1><MdDone className='text-xl' /></button>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )}
                                {/* ------------- Duration ------------- */}
                                <div className={`flex flex-col justify-center sm:p-0 w-10/12 lg:w-9/12 xl:w-6/12 mx-auto min-h-full transition-all ease-in-out duration-500 p-3 lg:p-6 xl:p-0 
                                ${!isLinkedin && isDuration ? "opacity-0 -translate-y-[1600%]" :
                                        isLinkedin && isDuration ? "opacity-0 -translate-y-[1700%]" :
                                            !isLinkedin && isLocation ? "opacity-1 -translate-y-[1500%]" :
                                                isLinkedin && isLocation ? "opacity-1 -translate-y-[1600%]" :
                                                    !isLinkedin && isMode ? "opacity-1 -translate-y-[1400%]" :
                                                        isLinkedin && isMode ? "opacity-1 -translate-y-[1500%]" :
                                                            !isLinkedin && isPaid ? "opacity-1 -translate-y-[1300%]" :
                                                                isLinkedin && isPaid ? "opacity-1 -translate-y-[1400%]" :
                                                                    isLinkedin ? "opacity-1 -translate-y-[1300%]" :
                                                                        isGuest ? "opacity-1 -translate-y-[1200%]" :
                                                                            isAudienceNumber ? "opacity-1 -translate-y-[1100%]" :
                                                                                isAudienceType ? "opacity-1 -translate-y-[1000%]" :
                                                                                    isAgenda1 ? "opacity-1 -translate-y-[900%]" :
                                                                                        isDate ? "opacity-1 -translate-y-[800%]" :
                                                                                            isInstitutionName ? "opacity-1 -translate-y-[700%]" :
                                                                                                isEventName ? "opacity-1 -translate-y-[600%]" :
                                                                                                    isAppointmentType ? "opacity-1 -translate-y-[500%]" :
                                                                                                        isSocialMedia ? "opacity-1 -translate-y-[400%]" :
                                                                                                            isEmail ? "opacity-1 -translate-y-[300%]" :
                                                                                                                isMobileNumber ? "opacity-1 -translate-y-[200%]" :
                                                                                                                    isName ? "opacity-1 -translate-y-full" : ""}`
                                }>
                                    <div className='relative'>
                                        <div className='flex items-center mr-2 absolute end-full mt-1 lg:mt-0.5 text-[8px] sm:text-[10px]'>
                                            <h1 className='text-[#666666] flex shrink-0 text-[14px] sm:text-[18px]'>{(guest === "Yes" && mode === "Offline" && 17) || (guest === "Yes" && mode === "Online" && 16) || (guest === "No" && mode === "Offline" && 16) || (guest === "No" && mode === "Online" && 15)}</h1>
                                            <FaArrowRight className='text-[#666666] flex shrink-0' />
                                        </div>
                                        <div className='text-[#000000]'>
                                            <h1 className='text-xl sm:text-2xl font-normal'>Meeting Duration?*</h1>
                                            <h3 className='text-lg sm:text-xl font-normal text-[#000000b3] mt-2'>Mark where applicable</h3>
                                        </div>
                                        <div className='mt-[32px] w-full flex flex-col max-w-full min-w-[168px] items-stretch'>
                                            <div className={`mb-2 w-[64%] sm:w-[28%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${duration === "15 Mins" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleDuration("15 Mins"); setDurationError('') }}>
                                                <div className={`my-1 mx-2 w-6 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699]  border-[1px] flex shrink-0 justify-center items-center ${duration === "15 Mins" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                                    <span className='font-bold'>A</span>
                                                </div>
                                                <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>15 Mins</h1>
                                                {duration === "15 Mins" && (
                                                    <IoMdCheckmark className='text-[#666666] flex shrink-0 my-auto text-end text-[32px] sm:text-[24px] mr-2' />
                                                )}
                                            </div>
                                            <div className={`mb-2 w-[64%] sm:w-[28%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${duration === "30 Mins" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleDuration("30 Mins"); setDurationError('') }}>
                                                <div className={`my-1 mx-2 w-6 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699]  border-[1px] flex shrink-0 justify-center items-center ${duration === "30 Mins" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                                    <span className='font-bold'>B</span>
                                                </div>
                                                <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>30 Mins</h1>
                                                {duration === "30 Mins" && (
                                                    <IoMdCheckmark className='text-[#666666] my-auto text-end text-[32px] sm:text-[24px] mr-2' />
                                                )}
                                            </div>
                                            <div className={`mb-2 w-[64%] sm:w-[28%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${duration === "45 Mins" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleDuration("45 Mins"); setDurationError('') }}>
                                                <div className={`my-1 mx-2 w-6 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699] border-[1px] flex shrink-0 justify-center items-center ${duration === "45 Mins" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                                    <span className='font-bold'>C</span>
                                                </div>
                                                <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>45 Mins</h1>
                                                {duration === "45 Mins" && (
                                                    <IoMdCheckmark className='text-[#666666] my-auto text-end text-[32px] sm:text-[24px] mr-2' />
                                                )}
                                            </div>
                                            <div className={`mb-2 w-[64%] sm:w-[28%] relative flex items-start sm:items-center rounded-[4px] bg-[#6666661a] hover:bg-[#6666664d] text-[#666666] min-h-[40px] py-1 break-words cursor-pointer opacity-1 shadow-radio-meeting-form ${duration === "1 Hour" ? "border-[1px] border-[#66666699]" : ""}`} onClick={() => { handleDuration("1 Hour"); setDurationError('') }}>
                                                <div className={`my-1 mx-2 w-6 h-6 min-h-[22px] rounded-[2px] text-xs border-[#66666699] border-[1px] flex shrink-0 justify-center items-center ${duration === "1 Hour" ? "bg-[#666666] text-[#ffffff]" : "bg-[#ffffffcc] text-[#666666]"}`}>
                                                    <span className='font-bold'>D</span>
                                                </div>
                                                <h1 className='w-full min-w-[100px] text-start break-word text-[16px] sm:text-[20px] text-[#666666] my-auto'>1 Hour</h1>
                                                {duration === "1 Hour" && (
                                                    <IoMdCheckmark className='text-[#666666] my-auto text-end text-[32px] sm:text-[24px] mr-2' />
                                                )}
                                            </div>
                                        </div>
                                        {duration && durationError === "" && (
                                            <div className='mt-[16px] w-full flex gap-4 items-center'>
                                                <button type='button' className='min-h-10 py-1.5 px-3.5 w-full sm:w-auto bg-[#0142ac] text-[#FFF] rounded-[4px] flex items-center text-md sm:text-xl gap-1 font-bold hover:bg-[#0142ac]/[80%] transition-colors duration-200' onClick={handleSubmit}>
                                                    <h1 className='mx-auto'>Submit</h1>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : null}
                </div>
            </div>
            <div className='flex justify-end absolute bottom-8 right-8'>
                <button className={`bg-[#0142ac] transition-colors duration-200 w-9 h-8 border-r-[1px] border-[#ffffff99] rounded-s-[4px] flex justify-center items-center ${isName ? "hover:bg-[#0142ac99] text-[#fff]" : "text-[#ffffff99]"}`} disabled={!isName} onClick={handleUpArrow}><FaChevronUp className='w-4 h-4' /></button>
                <button className={`bg-[#0142ac] transition-colors duration-200 w-9 h-8 rounded-e-[4px] flex justify-center items-center ${((appointmentType === "1:1 Business Proposal" || appointmentType === "1:1 Casual Meet" || appointmentType === "Other") && isAgenda) || ((appointmentType === "Invite as Chief Guest" || appointmentType === "Invite as Panel Judge" || appointmentType === "Invite as speaker") && mode === "Offline" ? isLocation : isMode) ? "text-[#ffffff99]" : "text-[#ffffff] hover:bg-[#0142ac99]"}`} onClick={handleDownArrow} disabled={((appointmentType === "1:1 Business Proposal" || appointmentType === "1:1 Casual Meet" || appointmentType === "Other") && isAgenda) || ((appointmentType === "Invite as Chief Guest" || appointmentType === "Invite as Panel Judge" || appointmentType === "Invite as speaker") && mode === "Offline" ? isLocation : isMode)}><FaChevronDown className='w-4 h-4' /></button>
            </div>
        </div>
    )
}
